import {Component, ElementRef, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatStepper, StepperOrientation} from '@angular/material/stepper';
import {Observable} from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';
import { AiDiagnosisService } from './ai-diagnosis.service';
import { Router } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-ai-diagnosis',
  templateUrl: './ai-diagnosis.component.html',
  styleUrls: ['./ai-diagnosis.component.scss']
})
export class AiDiagnosisComponent implements OnInit {
  isScrollable = false;

  step1Questions
  input
  gender:any;

  step2Questions
  islinear= true
  step3Questions
  selectedAnswers=[[],[],[],[],[],[],[]]
  answersDto=[]
  sevirtyList:any =[]
  loading = false
  filteredOptions: Observable<any>;
  symptoms = new FormControl('');
  selectedSymptoms: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA]
  errorMessage = null
  errorMsg =''
  ngOnInit(): void {
    this.gender =  localStorage.getItem('user_doctor')

 this.getQuestions()
 setTimeout(() => {
   this.filteredOptions = this.symptoms.valueChanges.pipe(
    debounceTime(300), // Apply debounce of 300ms
    startWith(''),
    map(value => this._filter(value || '')),
  
  );
 }, 500);
}
ngAfterViewInit() {
  const content = this.elRef.nativeElement.querySelector('.container-fluid'); // Replace '.content' with your target div class or id
  this.isScrollable = content.scrollHeight > content.clientHeight;
}
goForward(stepper: MatStepper){
  if (this.selectedSymptoms && this.selectedSymptoms.length > 0  && this.selectedSymptoms.length < 11 ){

    stepper.next();
    this.errorMsg = ''; // Clear any existing error message

  }else{
   if(this.selectedSymptoms.length ==0) this.errorMsg = 'this feild is required'
  }
}


//   private _filter(value: string): string[] {
    //     const filterValue = value.toLowerCase();
    // console.log(this.step1Questions.answers,"ans")
    //     return this.step1Questions.answers.filter(option => option.toLowerCase().includes(filterValue));
      // }
      private _filter(value: string): string[] {
        // debugger;
        // const filterValue = value.toLowerCase();
        // Add a check to ensure `this.step1Questions` is defined
        const filterValue = value.toLowerCase();
    if (!this.step1Questions) {
      console.warn('Questions not yet loaded');
      return [];
    }
    return this.step1Questions[0].answers.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
      //   console.log(this.step1Questions)
      //  const answer = this.step1Questions[0].answers.filter(option => option.toLowerCase().includes(filterValue));
      //  if(answer) {
      //   return answer;
      //  }else {
      //   console.log('not found')
      //  }
      }
      
       // Filter method with debounce
  // private _filter(value: string): Observable<string[]> {
  //   debugger;
  //   const filterValue = value.toLowerCase();
  //   if (!this.step1Questions) {
  //     console.warn('Questions not yet loaded');
  //     return new Observable(observer => observer.next([]));
  //   }
  //   console.log(this.step1Questions);
  //   return new Observable(observer => {
  //     const filtered = this.step1Questions[0].answers.filter(option =>
  //       option.toLowerCase().includes(filterValue)
  //     );
  //     observer.next(filtered);
  //   });
  // }
    


  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    thirdCtrl: ['', Validators.required],
  });
  stepperOrientation: Observable<StepperOrientation>;

  constructor(private _formBuilder: FormBuilder, private elRef: ElementRef, breakpointObserver: BreakpointObserver,private apiService:AiDiagnosisService,private router:Router) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({matches}) => (matches ? 'horizontal' : 'horizontal')));
  }
  getQuestions(){
    this.apiService.getQuestions().subscribe((res:[any])=>{
      this.step1Questions = res.filter(q => q.step === 1);
  this.step2Questions = res.filter(q => q.step === 2);
  this.step3Questions = res.filter(q => q.step === 3);

    },(error)=>{
      this.errorMessage= error.error.message
    })
  }


  constructQuestionAnswer(questionId: number, answer: any, e: any) {
    console.log(questionId, answer, e);
  
    // Find the index of the question in answersDto
    let index = this.answersDto.findIndex(obj => obj.question === questionId);
  
    // Manage selected answers
    if (!this.selectedAnswers[questionId]) {
      this.selectedAnswers[questionId] = [];
    }
  
    if (e.checked) {
      this.selectedAnswers[questionId].push(answer);
    } else {
      this.selectedAnswers[questionId] = this.selectedAnswers[questionId].filter(value => value !== answer);
    }
  
    // Update answersDto for non-question 1
    if (questionId !== 1) {
      if (index !== -1) {
        // If the question exists in answersDto, update its answer
        const existingAnswers = Array.isArray(this.answersDto[index].answer)
          ? this.answersDto[index].answer
          : this.answersDto[index].answer.split(',').map(item => item.trim());
  
        if (e.checked) {
          existingAnswers.push(answer);
        } else {
          // Remove unchecked answer
          this.answersDto[index].answer = existingAnswers.filter(item => item !== answer);
        }
  
        // Save the updated answers as a comma-separated string
        this.answersDto[index].answer = existingAnswers.join(', ');
  
      } else {
        // If the question doesn't exist, add it
        this.answersDto.push({ question: questionId, answer: [answer].join(', ') });
      }
    } else {
      // Special handling for question 1
      this.answersDto[0] = {
        question: questionId,
        answer: this.selectedAnswers[questionId].join(', '),
      };
      this.sevirtyList = this.selectedAnswers[questionId];
    }
  
    console.log('Updated answersDto:', this.answersDto);
  }
  
  
  check1stQuestion(){
    
  }
    sendResults(){
      this.loading =  true
      
this.apiService.sendResults(this.answersDto).subscribe((res:any)=>{

// catch id 
// send id in request => 
// response as history item 
this.apiService.aiDiagnosis =  res.details
localStorage.setItem('diagnoseID',res.id)
this.router.navigate(['/results'])
})
    }


    addSymptom(event: MatChipInputEvent): void {
      // debugger;
      console.log(event)
      console.log(event.input.value, 'add symptom');
      const value = (event.value || event.input.value || '').trim();
      if (value && !this.selectedSymptoms.includes(value)) {
        this.selectedSymptoms.push(value);
        console.log(this.selectedSymptoms,'sele')
        this.sevirtyList = this.selectedSymptoms
        this.islinear = false

      }
      // Clear the input value
      event.chipInput!.clear();
      // this.symptoms.setValue(null);
      this.symptoms.setValue('');
      if (event) {
        event.value = '';
      }
      event.chipInput!.clear();
      this.symptoms.setValue('');
  
    
      // this.sevirtyList = this.symptoms
// console.log(this.sevirtyList)

}

selectSymptom(event: MatAutocompleteSelectedEvent): void {
  console.log(event,'eveent')
  const value = event.option?.value || this.input;
  console.log(value)
  if (value && !this.selectedSymptoms.includes(value)) {
    this.selectedSymptoms.push(value);
    console.log(this.selectedSymptoms,'selected symptoms')
    if (this.selectedSymptoms && this.selectedSymptoms.length > 0){

      this.errorMsg = ''; // Clear any existing error message
  
    }

  }
  // this.symptoms.setValue(null);
  this.answersDto[0] = {question: 1, answer: this.selectedSymptoms.join(',') }
  this.sevirtyList = this.selectedSymptoms
  // this.symptoms.patchValue('')
  this.islinear = false
  this.symptoms.setValue('');
  const inputElement = document.querySelector('input[matInput]') as HTMLInputElement;
  if (inputElement) {

    inputElement.value = ''; // Clear the DOM input value explicitly
    inputElement.blur(); // Temporarily remove focus to reset visual input
    inputElement.focus(); // Restore focus for user interaction
  }

    }
  
    removeSymptom(symptom: string): void {
      const index = this.selectedSymptoms.indexOf(symptom);
      if (index >= 0) {
        this.selectedSymptoms.splice(index, 1);
        this.islinear = false
      }
      if (this.selectedSymptoms.length== 0){
        this.islinear= true
      }
      // this.islinear = true
  }

  onSymptomChange(event: any): void {
    this.selectedSymptoms = event.value;
    console.log(this.selectedSymptoms)
    this.errorMsg = this.selectedSymptoms.length ? '' : 'This field is required';
    this.answersDto[0] = {
      question: 1,
      answer: this.selectedSymptoms.join(', '),
    };
    console.log(this.answersDto)
    this.sevirtyList = this.selectedSymptoms;
    this.islinear = this.selectedSymptoms.length === 0;
  
  }
}


 


 

