import { Component, Inject, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HealthLibraryService } from '../health-library/health-library.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalService } from 'src/app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rating-dialog',
  templateUrl: './rating-dialog.component.html',
  styleUrls: ['./rating-dialog.component.scss']
})
export class RatingDialogComponent implements OnInit {
comment=''
currentRating = 0; // Initial rating
edit:boolean = false

  constructor(private apiService:HealthLibraryService,
    private modal: ModalService,
      private translate: TranslateService,
    
    public dialogRef: MatDialogRef<RatingDialogComponent>,

    @Inject(MAT_DIALOG_DATA)
    public data: { entity: any,
      review:any,
      articleId:number
     }



  ) { 
    console.log(this.data);
  }

  ngOnInit(): void {
    if(this.data.review){
      this.edit = true
      this.currentRating =  this.data.review.rating 
      this.comment =  this.data.review.comment 
    }
  }

selectedRating;

onRatingChange(rating: number): void {
  this.selectedRating = rating;
  // this.sendRating()
  console.log('Selected Rating:', rating);
}


sendRating(){

  let body= {
    rating:this.selectedRating,
comment:this.comment
  }

  if(this.edit){

    this.apiService.editRating(this.data.articleId,this.data.review.id, body).subscribe((res)=>{
      
      this.modal.snackMessage(this.translate.instant('reviewSubmitted'));
      this.dialogRef.close(res);
  
  
    })
  }
  else{
    
    this.apiService.postRating(this.data.entity, body).subscribe((res)=>{
      
      this.modal.snackMessage(this.translate.instant('reviewSubmitted'));
      this.dialogRef.close(res);
  
  
    })
  }
}
}
