import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
checked:boolean
isTokenAvailable: boolean = false;
constructor(        private router: Router,
) { }

  ngOnInit(): void {
    this.isTokenAvailable = !localStorage.getItem('token')
  }
checkTerms(){

}
routeFromTerms(){
if(localStorage.getItem("token")){
  this.router.navigate(['/register']);

}

   else if(localStorage.getItem("user_doctor")){
        this.router.navigate(['/ai-home']);

    }else if(localStorage.getItem("user_status"))
        {
        this.router.navigate(['/home']);

    } else{
        this.router.navigate(['/subscribe'])
    }
}
}
