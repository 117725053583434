import { P } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-subscribe-page',
  templateUrl: './subscribe-page.component.html',
  styleUrls: ['./subscribe-page.component.scss']
})
export class SubscribePageComponent implements OnInit {

  constructor(        private modal: ModalService,
    private apiService:ApiService,
    private router: Router,

  ) { }

  ngOnInit(): void {
  }
  subscribe(){
    
    this.apiService.subscribe().subscribe((res)=>{
      this.modal.snackMessage("Subscribed Successfully");

      this.router.navigate(['/ai-home']);
    })


  }

}
