

<div class="container">

<h1>{{'how_many_stars' | translate}}</h1>

<app-rating-stars
[(rating)]="currentRating"

  [maxStars]="5"
  (ratingChange)="onRatingChange($event)"
></app-rating-stars>
<div style="
height: 4rem;"></div>

<app-text-area   [(ngModel)]="comment"
[placeholder]="''"
                        >

                        </app-text-area>

                        <div class="destinations-btn-box">
                            <button
                                mat-raised-button
                                color="primary"
                                class="default-btn"
                                (click)="sendRating()"
                            >
                                {{ "Send" | translate }}
                            </button>
                        </div>
                    </div>
