import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAvatarService {
  private userAvatarKey = 'user_avatar';
  private userAvatarSubject = new BehaviorSubject<string | null>(this.getUserAvatar());

  // Observable to subscribe to
  userAvatar$ = this.userAvatarSubject.asObservable();

  constructor() {
    this.initializeLocalStorageListener();
  }

  private getUserAvatar(): string | null {
    return localStorage.getItem(this.userAvatarKey);
  }

  private initializeLocalStorageListener() {
    window.addEventListener('storage', (event) => {
      if (event.key === this.userAvatarKey) {
        this.userAvatarSubject.next(event.newValue);
      }
    });
  }

  updateUserAvatar(newAvatar: string): void {
    localStorage.setItem(this.userAvatarKey, newAvatar);
    this.userAvatarSubject.next(newAvatar);
  }
}
