


<div class="container" [formGroup]="securityForm">
<div class="row" >
         
    <h3 >
        {{ "security" | translate }}
    </h3>

    <div class="row">

        <form [formGroup]="securityForm" class="d-flex flex-column mt-4" (keyup.enter)="updatePassword()" (keydown)="preventEnterKey($event)"  >
            <section class="row align-items-center">
                <div class="col-md-2 label">{{"currentPassword"| translate}}</div>
                <div class="col-md-10">
                    <app-general-input
                        formControlName="password"
                        type="password"
                        [isPassword]="true"
                        [placeholder]="'currentPassword'| translate"
                        [errors]="getSecurityErrors('password')"
                        ></app-general-input>
                        <!-- [isSubmitted]="isSubmitted" -->
                        <!-- (valueChange)="updatePassword()" -->
                </div>
            </section>

          
   
            <div class="destinations-btn-box">
                <button
                    mat-flat-button
                    (click)="routeBack($event)"
                    (touchstart)="routeBack($event)"                    class="discard"
                >
                    {{ "Discard" | translate }}
                </button>
                <button
                    mat-raised-button
                    color="primary"
                    class="default-btn"
                    [disabled]="!securityForm?.value.password"
                    (click)="updatePassword()"
                >
                    {{ "Next" | translate }}
                </button>
     
            </div>
        </form>
    </div>

</div> 
</div>