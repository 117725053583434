



  <mat-tab-group class="container-fluid">
    <mat-tab [label]="'PERSONAL INFORMATION' | translate" class="custom-tab">
        <div class=" container">
        <form 
        (keyup.enter)="updatePersonalInfo()"
        (keydown)="preventEnterKey($event)"
            [formGroup]="personalForm"
            class="d-flex flex-column mt-4"
        >
            <section class="row">
                <div class="label col-md-2 mb-4">{{'photo'| translate | titlecase}}</div>
                <div class="col-md-10">
                    <app-image-upload
                        [img]="image"
                        formControlName="avatar"
                        (valueEmittedImage)="
                            handleImageSelected($event)
                        "
                        [isDisabled]="!isEdit"
                        [showEdit]="isEdit"
                    ></app-image-upload>
                </div>
            </section>
            <section class="row align-items-center">
                <div class="col-md-2 label">{{ 'name' | translate | titlecase}}</div>
                <div class="col-md-10">
                    <app-general-input
                        formControlName="name"
                        [placeholder]="'name' | translate"           
                        [errors]="getControlErrors('name')"
                        [isSubmitted]="isSubmitted"
                        [isDisabled]="!isEdit"
                    ></app-general-input>
                </div>
            </section>

            <section class="row align-items-center">
                <div class="col-md-2 label">{{"Email"| translate | titlecase}}</div>
                <div class="col-md-10">
                    <app-general-input
                        [isDisabled]="true"
                        formControlName="email"
                        [placeholder]="'Email' | translate" 
                        [errors]="getControlErrors('email')"
                        [isSubmitted]="isSubmitted"
                    ></app-general-input>
                    <P>
                    {{"change_email?"| translate}}<span class="link" (click)="changeEmail()">{{"click_here"|translate}}</span>

                    </P>
                </div>
            </section>

            <section class="row align-items-center mt-4">
                <div class="col-md-2 label mb-4">{{ 'gender' | translate | titlecase}}</div>
                <div class="col-md-10">
                    <mat-radio-group
                        [disabled]="!isEdit"
                        color="primary"
                        formControlName="gender"
                    >
                        <mat-radio-button value="1"
                            >{{ 'male' | translate }}</mat-radio-button
                        >
                        <mat-radio-button value="2"
                            >{{ 'female' | translate }}</mat-radio-button
                        >
                    </mat-radio-group>
                    <app-validation-errors
                        [isSubmitted]="isSubmitted"
                        [errors]="getControlErrors('gender')"
                    ></app-validation-errors>
                </div>
            </section>
            <section class="row align-items-center">
                <div class="col-md-2 label">{{ 'phone_number' | translate | titlecase }}</div>
                <div class="col-md-10">
                    <app-general-input
                        [isDisabled]="true"
                        formControlName="phone"
                        [placeholder]="'phone_number' | translate"
                        [errors]="getControlErrors('phone')"
                        [isSubmitted]="isSubmitted"
                    ></app-general-input>
                    <P>
                        {{"change_phone?"| translate}}<span class="link" (click)="changePhoneNumber()">{{"click_here"| translate}}</span>

                        </P>
                </div>
            </section>
            <div class="destinations-btn-box">
                <button
                    *ngIf="!isEdit"
                    mat-raised-button
                    color="primary"
                    class="default-btn"
                    (click)="EditPersonalInfo('personal')"
                >
                    {{ "EDIT" | translate }}
                </button>
            </div>
            <div *ngIf="isEdit" class="destinations-btn-box">
                <button
                    mat-flat-button
                    (click)="deleteTerm($event)"
                    class="discard"
                >
                    {{ "Discard" | translate }}
                </button>
                <button
                    mat-raised-button
                    color="primary"
                    class="default-btn"
                    (click)="updatePersonalInfo()"
                >
                    {{ "SAVE" | translate }}
                </button>
                <!-- color="warn" -->
                <!-- class="default-btn" -->
            </div>
        </form>
    </div> </mat-tab>
    <mat-tab [label]="'Medical Information' | translate"  class="custom-tab"> 
        <div class=" mt-3  container ">
            <div class="d-flex mb-3">
                <div class="chart position-relative">
                    <div class="
                    percentage-num
                    ">{{percentage| number: '1.0-0'}} %</div>

                    <div class="chart position-relative" style="z-index: 2">
                        <canvas
                            baseChart
                            [datasets]="doughnutChartDatasets"
                            [options]="doughnutChartOptions"
                            [legend]="true"
                            [type]="'doughnut'"
                        >
                        </canvas>
                        <!-- [labels]="doughnutChartLabels"/ -->
                    </div>
                </div>
                <div>
                    <p class="title">{{ 'medical_information' | translate | titlecase}}</p>
                    <p class="info">
                        {{ 'complete_profile_prompt' | translate }}
                    </p>
                </div>
            </div>
            <form [formGroup]="medicalForm" class="d-flex flex-column mt-4"      (keydown)="preventEnterKey($event)"     (keyup.enter)="updateMedicalInfo()"
            >
                
            <section class="row mb-2">
                <div class="label col-md-2">{{'your_ai_dr'| translate | titlecase}}</div>
                <div class="col-md-10">
                  <div   style="position: relative; width:fit-content"
                  (click)="changeAiDoctor()">
                    <div  *ngIf="gender ==1" class="doctor">
                        <img src="./../../../../assets/home/AI_Aiden-removebg-preview.png">
                    </div>
                    <div *ngIf="gender ==2" class="doctor">
                        <img src="./../../../../assets/home/AI_Ava-removebg-preview.png">
                    </div>
                    <!-- *ngIf="isMedicalEdit" -->
                    <div class="icon" >
                        <img
                            src="./../../../../.././../../assets/images/profile/edit.svg"
                        />
                    </div>
                  </div>
                </div>
            </section>
                <section class="row align-items-center">
                    <div class="col-md-2 label mb-4">{{ 'date_of_birth' | translate | titlecase}}</div>
                    <div class="col-md-10">
                  
                        <app-date-input
                        formControlName="dob"
                        [placeholder]="'date_of_birth' | translate "       
                        [errors]="getMedicalErrors('dob')"
                        [isSubmitted]="isSubmitted"
                        [isDisabled]="!isMedicalEdit">

                        </app-date-input>
                    </div>
                </section>

                <section class="row align-items-center">
                    <div class="col-md-2 label">{{ 'height' | translate | titlecase }}</div>
                    <div class="col-md-10">
                        <app-general-input
                            formControlName="height"
                            [placeholder]=" 'height' | translate "
                            type="number"
                                                        [errors]="getMedicalErrors('height')"
                            [isSubmitted]="isSubmitted"
                            [isDisabled]="!isMedicalEdit"
                        ></app-general-input>
                    </div>
                </section>
                <section class="row align-items-center mt-4">
                    <div class="col-md-2 label mb-4">{{ 'blood_type' | translate | titlecase}}</div>
                    <div class="col-md-10 mt-2">
                     
                        <mat-form-field class="input-container__input mt-2" appearance="outline">

                            <mat-select
                            formControlName="blood_type"

                            [placeholder]="'blood_type' | translate"
                            [disabled]="!isMedicalEdit"
                          >
                            <mat-option
                              *ngFor="let item of bloodTypes"
                              [value]="item"
                            >
                              {{ item }}
                            </mat-option>
                          </mat-select>
                          </mat-form-field>
                    </div>
                </section>
                <section class="row align-items-center">
                    <div class="col-md-2 label">{{ 'weight' | translate | titlecase }}</div>
                    <div class="col-md-10">
                        <app-general-input
                        
                            formControlName="weight"
                            [placeholder]="'weight' | translate"
                            [errors]="getMedicalErrors('weight')"
                            [isSubmitted]="isSubmitted"
                            [isDisabled]="!isMedicalEdit"
                        ></app-general-input>
                    </div>
                </section>

                <section class="row align-items-center  mt-4">
                    <div class="col-md-2 label mb-4">{{ 'long_term_disease' | translate | titlecase }}</div>
                    <div class="col-md-10  mt-2">
                      
                        
                        <mat-form-field class="input-container__input mt-2" appearance="outline">

                            <mat-select
                            formControlName="long_term_diseases"
                            multiple="true"
                            [placeholder]="'long_term_disease' | translate"
                            [disabled]="!isMedicalEdit"
                          >
                            <mat-option
                              *ngFor="let item of longTermDiseases"
                              [value]="item.id"
                            >
                              {{ lang == "en"?item.name: item.name_ar }}
                            </mat-option>
                          </mat-select>
                          </mat-form-field>
                    </div>
                </section>

                <section class="row align-items-center  mt-4">
                    <div class="col-md-2 label mb-4">{{ 'previous_surgeries' | translate | titlecase}}</div>
                    <div class="col-md-10  mt-2">
                     
                              <mat-form-field class="input-container__input mt-2" appearance="outline">

                            <mat-select
                            formControlName="previous_surgeries"
                                multiple="true"
                                [placeholder]="'previous_surgeries' | translate "
                            [disabled]="!isMedicalEdit"
                          >
                            <mat-option
                              *ngFor="let item of surgeries"
                              [value]="item.id"
                            >
                              {{ lang == "en"?item.name :item.name_ar}}
                            </mat-option>
                          </mat-select>
                          </mat-form-field>
                    </div>
                </section>

                <section class="row align-items-center  mt-4">
                    <div class="col-md-2 label mb-4">{{ 'family_history' | translate | titlecase}}</div>
                    <div class="col-md-10  mt-2">
                   
                        <mat-form-field class="input-container__input mt-2" appearance="outline">
                            <mat-select
                            formControlName="family_medical_history"
                            multiple="true"
                            [placeholder]="'family_history' | translate "
                            [disabled]="!isMedicalEdit"
                          >
                            <mat-option
                              *ngFor="let item of familyHistory"
                              [value]="item.id"
                            >
                              {{ lang == "en"?item.name :item.name_ar}}
                            </mat-option>
                          </mat-select>
                          </mat-form-field>
                    </div>
                </section>

                <section class="row align-items-center  mt-4">
                    <div class="col-md-2 label mb-4">{{ 'allergies' | translate | titlecase}}</div>
                    <div class="col-md-10  mt-2">
                       

                        <mat-form-field class="input-container__input mt-2" appearance="outline">
                            <mat-select
                            formControlName="allergies"
                            multiple="true"
                            [placeholder]="'allergies' | translate "
                            [disabled]="!isMedicalEdit"
                          >
                            <mat-option 
                              *ngFor="let item of allergies"
                              [value]="item.id"
                            >
                              {{ lang == "en"?item.name :item.name_ar}}
                            </mat-option>
                          </mat-select>
                          </mat-form-field>
                    </div>
                </section>

                <section class="row align-items-center mt-3">
                    <div class="col-md-2 label mb-4">{{ 'medical_notes' | translate | titlecase }}</div>
                    <div class="col-md-10">
                        <app-text-area   [errors]="getMedicalErrors('medical_notes')"
                        [isSubmitted]="isSubmitted"
                        formControlName="medical_notes"
                        [isDisabled]="!isMedicalEdit"
                        >

                        </app-text-area>
                        <!-- 
                           
                        ></app-general-input> -->
                    </div>
                </section>
                <div class="destinations-btn-box">
                    <button
                        *ngIf="!isMedicalEdit"
                        mat-raised-button
                        color="primary"
                        class="default-btn"
                        (click)="EditPersonalInfo('m')"
                    >
                        {{ "EDIT" | translate }}
                    </button>
                </div>
                <div *ngIf="isMedicalEdit" class="destinations-btn-box">
                    <button
                        mat-flat-button
(click)="getMedicalData($event)"                        class="discard"
                    >
                        {{ "Discard" | translate }}
                    </button>
                    <button
                        mat-raised-button
                        color="primary"
                        class="default-btn"
                        (click)="updateMedicalInfo()"
                    >
                        {{ "SAVE" | translate }}
                    </button>
         
                </div>
            </form>
        </div> </mat-tab>
  </mat-tab-group>
  
  