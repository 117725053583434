import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { HealthLibraryService } from "./health-library.service";
import { DOCUMENT } from "@angular/common";

@Component({
    selector: "app-health-library",
    templateUrl: "./health-library.component.html",
    styleUrls: ["./health-library.component.scss"],
})
export class HealthLibraryComponent implements OnInit {
    categories: any[] = [];
    notFound = false
    data:any = [
        // Your JSON data here...
      ];
      inputValue: string = '';

      lang: string = "";
      htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    dir = localStorage.getItem('dir') || 'ltr';
  categorizedData = {
    news: [],
    medication: [],
    dietPlan: [],
    dentistry: [],
    psychiatry: [],
    vaccination: [],
    diseases: []
  };
  

    constructor(
        private apiService: HealthLibraryService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,

        private route: ActivatedRoute
    ) {
        
    }

    ngOnInit(){
        this.getHealthLibrary()
        
        localStorage.setItem('dir', this.dir);
    }

    getHealthLibrary(){
      this.notFound = false

      this.data =[]

        this.apiService.getHealthLibrary(this.inputValue).subscribe((res)=>{
            console.log(res)
            this.data = res
            if(this.data.length == 0){
              this.notFound = true
            }
            this.categorizeData();

        })

    }




  categorizeData() {
    
  this.categorizedData = {
    news: [],
    medication: [],
    dietPlan: [],
    dentistry: [],
    psychiatry: [],
    vaccination: [],
    diseases: []
  };
  this.data.forEach(item => {
      switch (item.type) {
        case 1:
          this.categorizedData.news.push(item);
          break;
        case 2:

          this.categorizedData.medication.push(item);
          break;
        case 3:
          this.categorizedData.dietPlan.push(item);
          break;
        case 4:
          this.categorizedData.dentistry.push(item);
          break;
        case 5:
          this.categorizedData.psychiatry.push(item);
          break;
        case 6:
          this.categorizedData.vaccination.push(item);
          break;
        case 7:
          this.categorizedData.diseases.push(item);
          break;
        default:
          console.log(`Unknown type ${item.type}`);
      }

    });
    console.log(this.categorizedData)
  }



}


